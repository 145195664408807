<template>
  <div class="investor-lifecycle">
    <div
      id="step-find"
      class="investor-step square"
    >
      <div class="step-header">
        <div class="number">1</div>
        <h2
          ref="stepFindTitle"
          class="title"
        >
          <strong>Trouver</strong>
          un bien rentable
        </h2>
      </div>
      <div class="step-content-wrapper no-padding-mobile">
        <div class="step-content">
          <div class="card no-background">
            <h3 class="title">
              Investissement locatif
              <strong>clé en main</strong>
            </h3>
            <div class="desc">
              Un chasseur immobilier déniche rapidement pour vous le bien idéal et vous accompagne tout au
              long de votre projet.
            </div>
            <NuxtLink
              class="actions"
              href="https://horiz.io/ressources/investissement-locatif-cle-en-main?utm_source=horiz&utm_medium=hp-cta-2&utm_campaign=cle-en-main"
              no-prefetch
              @click="handleClick('Landing Clé en main')"
            >
              <uikit-button type="primary">Être accompagné</uikit-button>
            </NuxtLink>
          </div>
          <div class="card-image">
            <picture>
              <source
                media="(max-width: 1024px)"
                srcset="~/assets/imgs/home/investissement-locatif-cle-en-main-182w.webp"
              />
              <img
                src="~/assets/imgs/home/investissement-locatif-cle-en-main-307w.webp"
                alt="service d’investissement locatif clé en main"
                loading="lazy"
                width="307"
                height="394"
              />
            </picture>
          </div>
          <cards-card-plugin />
          <cards-card-alerts-app />
          <nuxt-lazy-hydrate when-visible>
            <lazy-tub-carousel class="tub-carousel" />
          </nuxt-lazy-hydrate>
        </div>
      </div>
    </div>
    <div
      id="step-simulate"
      class="investor-step wide"
    >
      <div class="step-header">
        <div class="number">2</div>
        <h2
          ref="stepSimulateTitle"
          class="title"
        >
          <strong>Simuler</strong>
          la performance
        </h2>
      </div>
      <div class="step-content-wrapper">
        <div class="step-content">
          <div
            id="step-simulate-app-image"
            class="app-image"
          >
            <div class="app-image-content">
              <img
                id="app-image"
                alt="calculer le rendement locatif"
                width="353"
                height="410"
                loading="lazy"
                src="~/assets/imgs/home/app/simulateur-rendement-locatif.png"
              />
              <img
                id="app-image-gain"
                ref="appImageGain"
                alt="calculer le rendement locatif (plus value)"
                loading="lazy"
                src="~/assets/imgs/home/app/gain.png"
              />
              <img
                id="app-image-loan"
                ref="appImageLoan"
                alt="calculer le rendement locatif (emprunt)"
                loading="lazy"
                src="~/assets/imgs/home/app/loan.png"
              />
              <img
                id="app-image-performance"
                ref="appImagePerformance"
                alt="calculer le rendement locatif (performance)"
                loading="lazy"
                src="~/assets/imgs/home/app/performance.png"
              />
              <img
                id="app-image-taxes"
                ref="appImageTaxes"
                alt="calculer le rendement locatif (impôts)"
                loading="lazy"
                src="~/assets/imgs/home/app/taxes.png"
              />
              <img
                id="app-image-rent"
                ref="appImageRent"
                alt="calculer le rendement locatif (loyer)"
                loading="lazy"
                src="~/assets/imgs/home/app/rent.png"
              />
            </div>
          </div>
          <div
            id="step-simulate-simulation"
            class="card no-background no-padding"
          >
            <h3 class="title">
              <strong>Simulateur</strong>
              de rendement locatif
            </h3>
            <div class="desc">
              Accédez aux indicateurs de performance de votre investissement immobilier personnalisés selon le
              régime de location (LMNP, location nue, saisonnière…).
            </div>
            <NuxtLink
              no-prefetch
              to="/rendement-locatif"
              @click="handleClick('Landing Simulateur')"
            >
              <uikit-button type="link">
                Calculer
                <template #right-icon>
                  <uikit-arrow-icon
                    class="arrow-icon"
                    gradient="no-gradient"
                  />
                </template>
              </uikit-button>
            </NuxtLink>
          </div>
          <div
            id="step-simulate-management"
            class="card no-background no-padding"
          >
            <h3 class="title">
              <strong>Gestion</strong>
              de votre patrimoine immobilier
            </h3>
            <div class="desc">
              Suivez en temps réel les performances de votre patrimoine immobilier et comparez-les avec celles
              d’autres investissements.
            </div>
            <NuxtLink
              :external="true"
              href="https://horiz.io/investissement-immobilier/gestion-de-patrimoine-immobilier "
              no-prefetch
              target="_blank"
              @click="handleClick('Youtube Patrimoine')"
            >
              <uikit-button type="link">
                Découvrir
                <template #right-icon>
                  <uikit-arrow-icon
                    class="arrow-icon"
                    gradient="no-gradient"
                  />
                </template>
              </uikit-button>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
    <div
      id="step-optimize"
      class="investor-step square"
    >
      <div class="step-header">
        <div class="number">3</div>
        <h2
          ref="stepOptimizeTitle"
          class="title"
        >
          <strong>Optimiser</strong>
          la rentabilité
        </h2>
      </div>
      <div class="step-content-wrapper">
        <div class="step-content">
          <div class="card-image">
            <picture>
              <source
                media="(max-width: 1024px)"
                srcset="~/assets/imgs/home/immobilier-locatif-282.webp"
              />
              <img
                src="~/assets/imgs/home/immobilier-locatif-460.webp"
                alt="optimiser l’investissement immobilier locatif"
                loading="lazy"
                width="460"
                height="382"
              />
            </picture>
          </div>
          <div
            id="step-optimize-gestion"
            class="card no-background"
          >
            <h3 class="title">
              <strong>Gestion locative</strong>
              en ligne
            </h3>
            <div class="desc">
              Bail pré-rempli, envoi de quittance automatisé, modèles de documents, alerte en cas de loyer
              impayé, aide à la déclaration des revenus fonciers...
            </div>
            <NuxtLink
              no-prefetch
              to="/gestion-locative"
              @click="handleClick('Landing Gestion')"
            >
              <uikit-button type="link">
                Découvrir
                <template #right-icon>
                  <uikit-arrow-icon
                    class="arrow-icon"
                    gradient="no-gradient"
                  />
                </template>
              </uikit-button>
            </NuxtLink>
          </div>
          <div class="card">
            <h3 class="title">
              Assurance
              <strong>PNO</strong>
            </h3>
            <div class="desc">
              Souscrivez à tarif négocié à une assurance Propriétaire Non Occupant obligatoire pour tout
              propriétaire bailleur.
            </div>
            <NuxtLink
              no-prefetch
              external
              :href="PNO_PARTNER_URL"
              @click="handleClick('Landing PNO')"
            >
              <uikit-button type="link">
                Souscrire
                <template #right-icon>
                  <uikit-arrow-icon
                    class="arrow-icon"
                    gradient="no-gradient"
                  />
                </template>
              </uikit-button>
            </NuxtLink>
          </div>
          <div class="card">
            <h3 class="title">
              Assurance
              <strong>GLI</strong>
            </h3>
            <div class="desc">
              Souscrivez à une assurance loyers impayés (GLI) qui vous indemnise dès le 1er mois d’impayé.
            </div>
            <NuxtLink
              no-prefetch
              external
              :href="GLI_PARTNER_URL"
              @click="handleClick('Landing GLI')"
            >
              <uikit-button type="link">
                Souscrire
                <template #right-icon>
                  <uikit-arrow-icon
                    class="arrow-icon"
                    gradient="no-gradient"
                  />
                </template>
              </uikit-button>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { UikitArrowIcon, UikitButton } from '@hz/ui-kit'
import { GLI_PARTNER_URL, PNO_PARTNER_URL } from '@/config/config'

const handleClick = (destination) => useTracking().track('[HP] Clic CTA', { destination })

const observer = ref(null)
const stepFindTitle = ref(null)
const stepSimulateTitle = ref(null)
const stepOptimizeTitle = ref(null)

const appImageGain = ref(null)
const appImageLoan = ref(null)
const appImagePerformance = ref(null)
const appImageTaxes = ref(null)
const appImageRent = ref(null)

const animatedElements = ref([appImageGain, appImageLoan, appImagePerformance, appImageTaxes, appImageRent])
const animation = ref({})

onMounted(() => {
  observer.value = new IntersectionObserver(handleTitleInView, { rootMargin: '-80px 0px 0px 0px' })
  observer.value.observe(stepFindTitle.value)
  observer.value.observe(stepSimulateTitle.value)
  observer.value.observe(stepOptimizeTitle.value)

  animatedElements.value.forEach((element) => {
    const posY = window.pageYOffset - appImageGain.value.offsetTop
    const posTop = posY + appImageGain.value.getBoundingClientRect().bottom / 2

    animation[element.value] = {
      posY,
      posTop,
      base: (posY - posTop + window.innerHeight) / (appImageGain.value.clientHeight + window.innerHeight),
    }
  })
})
onUnmounted(() => {
  observer.value.disconnect()
})

const handleTitleInView = (entry) => {
  if (entry[0].isIntersecting) {
    entry[0].target.classList.add('play')
  } else {
    entry[0].target.classList.remove('play')
  }
}
</script>

<style lang="less" scoped>
.investor-lifecycle {
  padding-bottom: 100px;

  .investor-step {
    strong {
      position: relative;
      color: var(--ds-color-primary-100);
      font-weight: inherit;
    }

    .arrow-icon {
      width: 16px;
      height: auto;
    }

    .step-header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 26px;
      margin-bottom: 42px;

      .number {
        position: relative;
        font-size: 9rem;
        font-weight: bold;
        color: var(--ds-color-primary-100-lighten);
      }

      .title {
        font-size: 36px;
        font-weight: var(--ds-weight-semi-bold);
        color: var(--ds-color-gray-100);
        margin-top: 1rem;
        text-align: center;

        strong:before {
          position: absolute;
          content: '';
          background-color: var(--ds-color-primary-25);
          width: 100%;
          height: 23px;
          left: 0;
          bottom: 0;
          z-index: -1;
          transform: scaleX(0);
          transform-origin: 0 0;
          animation-duration: 1s;
          animation-delay: 0.2s;
          animation-timing-function: ease;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        &.play {
          strong:before {
            animation-name: expand;
          }
        }
      }
    }

    @keyframes expand {
      0% {
        transform: scaleX(0);
      }

      100% {
        transform: scaleX(1);
      }
    }

    .step-content-wrapper {
      width: 100%;
      padding: 0 80px;
    }

    .step-content {
      max-width: 1240px;
      margin: auto;
      display: grid;
      grid-template: ~'1fr 1fr / 1fr 1fr';
      grid-gap: 42px;
    }

    &.square {
      .step-content {
        background-color: var(--ds-color-primary-25);
        border-radius: 12px;
        padding: 52px 32px;
      }
    }

    &.wide {
      .step-content-wrapper {
        background-color: var(--ds-color-primary-25);
      }

      .step-content {
        padding: 60px 0;
        margin: auto;
      }
    }

    .card {
      padding: 32px 46px;
      background-color: var(--ds-color-white-100);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: var(--ds-space-2lg);

      &.no-background {
        background-color: transparent;
      }

      &.no-padding {
        padding: 0;
      }

      .title {
        font-size: 26px;
        font-weight: var(--ds-weight-semi-bold);
        color: var(--ds-color-gray-100);
        margin: 0;
      }

      .desc {
        font-size: 18px;
        color: var(--ds-color-gray-50);
      }

      .actions {
        padding-top: 12px;
      }
    }

    .card-image {
      position: relative;
      display: flex;
      justify-content: center;

      picture {
        max-height: calc(100% + 134px);
        max-width: 100%;
        bottom: -42px;
        position: absolute;
        width: auto;
        height: auto;
        aspect-ratio: auto;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  #step-simulate .app-image {
    grid-row: span 2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 410px;

    .app-image-content {
      position: absolute;
      height: 100%;
      margin-top: -20px;
    }

    #app-image {
      position: relative;
      height: 100%;
      width: auto;
    }

    #app-image-gain {
      height: 15%;
      bottom: -6%;
      left: -3%;
    }

    #app-image-loan {
      height: 12.5%;
      bottom: 9%;
      right: -18%;
    }

    #app-image-performance {
      height: 32%;
      bottom: 14%;
      left: -21%;
    }

    #app-image-taxes {
      height: 12.5%;
      bottom: 26%;
      right: -6%;
    }

    #app-image-rent {
      height: 12.5%;
      bottom: 43%;
      right: -20%;
    }

    img:not(:first-child) {
      position: absolute;
    }
  }

  @media @bp-md-down {
    padding-bottom: 50px;

    .investor-step {
      .step-header {
        flex-direction: column;
        gap: 0;

        .number {
          font-size: 6rem;
        }

        .title {
          font-size: 32px;
          margin-top: 0;
        }
      }

      .card {
        padding: 20px;

        .title {
          font-size: 22px;
        }

        .desc {
          font-size: var(--ds-font-sm);
        }

        .actions button {
          width: 100%;
        }
      }

      &.square {
        .step-content {
          padding: 20px 16px;
        }
      }

      .step-content-wrapper {
        width: 100%;
        padding: 0 32px;
      }

      .step-content {
        grid-template: none;
        grid-gap: 32px;
      }

      .card-image {
        height: 234px;
        margin: -32px 0;

        picture {
          max-height: 100%;
          bottom: 0;
        }
      }
    }

    #step-simulate {
      .app-image {
        grid-row: auto;
        height: 304px;
      }

      #step-simulate-simulation {
        order: 1;
      }

      #step-simulate-app-image {
        order: 2;
      }

      #step-simulate-management {
        order: 3;
      }
    }

    #step-optimize-gestion {
      order: -1;
    }
  }

  @media @bp-xs {
    #step-simulate .app-image {
      height: 70vw;
    }
  }

  @media @bp-lg {
    .dotted-line {
      position: absolute;
      content: '';
      z-index: -1;
      height: 100%;
      width: 3px;
      top: 250px;
      left: 50%;
      transform: translateY(-50%);

      background-image: linear-gradient(var(--ds-color-primary-100-lighten) 55%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 3px 23px;
      background-repeat: repeat-y;
    }

    #step-find .number:after {
      .dotted-line();
      left: calc(50% + 5px);
    }

    #step-simulate .number {
      &:before {
        .dotted-line();
        top: -40px;
      }

      &:after {
        .dotted-line();
      }
    }

    #step-optimize .number {
      &:before {
        .dotted-line();
        top: -40px;
      }
    }
  }
}

@media @bp-mobile {
  .no-padding-mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.tub-carousel {
  grid-column-start: 1;
  grid-column-end: 2;
  overflow: hidden;

  :deep(.tub-category-selector) {
    .hider.left {
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 12.64%, var(--ds-color-primary-25) 100%);
    }
    .hider.right {
      background: linear-gradient(270deg, var(--ds-color-primary-25) 12.64%, rgba(255, 255, 255, 0) 100%);
    }
  }
}

@media @bp-desktop {
  .tub-carousel {
    grid-column-start: 1;
    grid-column-end: 3;
    overflow: hidden;
  }
}
</style>
